import { DiscoverServiceView } from "app/components/ifttt_next/services/discover_service_view"
import InitializeFacebook from "app/scripts/initialize_facebook"
import SetDatadogRumContext from "app/scripts/dd_rum_context"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import ShareButton from "shared/components/share_button"

Object.assign(window.App.Comps, {
  DiscoverServiceView,
  ShareButton,
})

Object.assign(window.App.Scripts, {
  InitializeFacebook,
  SetDatadogRumContext,
  SetupUnifiedHeaderDropdown,
  SetupUnifiedMobileMenu,
})
